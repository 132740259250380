<template>
  <default-layout>
    <section>
      <div class="form-inline">
        <span class="form-inner">
          <span class="font-weight-bold">テスト：</span>
          <input-select v-model="examTermId" @change="changeExamTerm" :null-option="examTerms.length == 0"
                        :options="examTerms"
                        :opt-to-value="x => x"
                        :opt-to-input-value="t => t.examTermId"
                        :opt-to-label="t => t.name"
                        :opt-is-selected="t => t.examTermId == examTermId"/>
          <span v-if=" examTerms.length == 0"> 選択可能なテストがありません</span>
        </span>
        <span class="form-inner">
          <span class="font-weight-bold">実施会場：</span>
          <input-select v-model="siteId" @change="loadExaminees" :null-option="sites.length == 0"
                        :options="sites"
                        :opt-to-value="x => x"
                        :opt-to-input-value="s => s.siteId"
                        :opt-to-label="s => s.code + ': ' + s.name"
                        :opt-is-selected="s => s.siteId == siteId"/>
          <span v-if="examTerms.length > 0 && sites.length == 0"> 選択可能な教室がありません</span>
        </span>
        <span class="form-inner url-button-container" v-if="examTerm && !examTerm.ended">
          <button class="url-button" @click="openUrlModal">受検URL表示</button>
        </span>
      </div>
      <div class="form-inline pt-2" v-if="examTerm">
        <span class="form-inner">
          <span class="font-weight-bold">実施期間：</span> {{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}
        </span>
      </div>
    </section>
    <section v-if="examTerm">
      <h2>
        <span class="material-icons">face</span>受検者リスト
        <div class="output-buttons">
          <button v-button:download @click="downloadExamineeList()" v-if="listFile">受検者一覧出力</button>
          <button v-button:download @click="downloadExamineeCard()" v-if="cardFile">受検票出力</button>
        </div>
      </h2>
      <panel>
        <form class="form-inline" v-form>
          <div class="row">
            <div class="col-md-12">
              <span class="font-weight-bold">受検級：</span>
              <input-radios v-model="examGradeCode" name="examGrade" :options="examGradeOptions" :checkoffEnabled="false"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span class="font-weight-bold">受検状況：</span>
              <input-radios v-model="state" name="state" :options="stateOptions" :checkoffEnabled="false"/>
            </div>
            <div class="col-md-6">
              <span class="font-weight-bold">間違い申告：</span>
              <input-checkbox v-model="correctionRequired" label="申告あり"/>
            </div>
          </div>
        </form>
      </panel>
      <correct-legend/>
      <answer-legend/>
      <div class="table-responsive">
        <table class="table-bordered" v-table:default.hover v-cols="[9,13,20,6,10,12,8,11,11]">
          <thead>
          <tr>
            <th>受検番号</th>
            <th>名前</th>
            <th>ローマ字名</th>
            <th>性別</th>
            <th>生年月日</th>
            <th>学年</th>
            <th>受検級</th>
            <th>試験開始</th>
            <th>試験終了</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr>
            <td colspan="9" class="text-center"><loading /></td>
          </tr>
          </tbody>
          <tbody v-else>
          <template v-for="(e,index) in examinees">
          <tr :key="e.examieeId" @click="editExaminee(e.examineeId)" :class="getRowClass(index)">
            <td class="text-center" :rowspan="isDisplayStatus(e.examineeId) ? 2:1">{{e.examineeNumber}}</td>
            <td :class="{wrong: e.nameWrong, corrected: e.nameCorrected}">
              {{e.familyName}} {{e.givenName}}
            </td>
            <td :class="{wrong: e.nameEnWrong, corrected: e.nameEnCorrected}">
              {{e.givenNameEn}} {{e.familyNameEn}}
            </td>
            <td class="text-center" :class="{wrong: e.sexWrong, corrected: e.sexCorrected}">
              {{e.sex.name}}
            </td>
            <td class="text-center" :class="{wrong: e.dateOfBirthWrong, corrected: e.dateOfBirthCorrected}">
              {{e.dateOfBirth | date('YYYY/MM/DD')}}
            </td>
            <td class="text-center" :class="{wrong: e.schoolGradeWrong, corrected: e.schoolGradeCorrected}">
              {{e.schoolGrade.name}}
            </td>
            <td class="text-center">
              {{e.examGradeName }}
            </td>
            <td class="text-center">{{(examStatus[e.examineeId] ? examStatus[e.examineeId].startTime : null) | timestamp}}</td>
            <td class="text-center">{{(examStatus[e.examineeId] ? examStatus[e.examineeId].endTime : null) | timestamp}}</td>
          </tr>
          <tr :key="e.examieeId" v-if="isDisplayStatus(e.examineeId)" @click="!edit && editExaminee(e.examineeId)" :class="getRowClass(index)">
            <td colspan="9" style="padding: 5px 0 0 5px">
              <template v-for="(s, index) in (examStatus[e.examineeId].status)">
                <span :key="index">
                  <span v-if="s==='C'" aria-hidden="true" class="material-icons question-type">face</span>
                  <span v-else-if="s==='L'" aria-hidden="true" class="material-icons question-type">circle</span>
                  <span v-else-if="s==='LN'" aria-hidden="true" class="material-icons question-type">radio_button_unchecked</span>
                  <span v-else-if="s==='R'" aria-hidden="true" class="material-icons question-type">square</span>
                  <span v-else-if="s==='RN'" aria-hidden="true" class="material-icons question-type">check_box_outline_blank</span>
                  <span v-else aria-hidden="true" class="question-type sample"></span>
                </span>
              </template>
            </td>
          </tr>
          </template>
          </tbody>
        </table>
      </div>
    </section>
    <modal ref="examineeModal" title="受検者情報" v-if="examTerm">
      <examinee-modal :site-id="siteId" :examinee-id="examineeId" :exam-term="examTerm" @close="closeExamineeModal"></examinee-modal>
    </modal>
    <modal ref="urlModal" title="受検URL" v-if="examTerm">
      <url-modal :site-id="siteId" :exam-term-id="examTerm.examTermId" @close="closeUrlModal"></url-modal>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from "../_resource";
import {mapState} from "vuex";
import Modal from "@/calico-vue/ui/components/Modal";
import ExamineeModal from "./ExamineeModal";
import UrlModal from "./UrlModal";
import CorrectLegend from "./CorrectLegend";
import {Loading} from "@/lib/components";
import AnswerLegend from "../AnswerLegend.vue";

export default {
  components: {Loading, Modal, ExamineeModal, UrlModal, CorrectLegend, AnswerLegend},
  data() {
    return {
      examineeId: null,
      examTermId: null,
      siteId: null,
      examGradeCode: "all",
      state: false,
      sites: [],
      allExaminees: [],
      listFile: false,
      cardFile: false,
      examGrades: [],
      examGradeCodes: {},
      correctionRequired: false,
      loading: true,
      timeoutHandle: null,
      examStatus: {},
      reloadHandle: null,
    };
  },
  computed: {
    ...mapState([
      "examTerms",
      "currentExamTerm",
    ]),
    examinees() {
      return this.allExaminees
          .filter(e => this.examGradeCode === "all"
              || this.examGradeCode === e.examGradeCode
              || (this.examGradeCode === "0" && e.examGradeCode === null))
          .filter(e => this.state === false
              || (this.state === 1 && !e.startTime)
              || (this.state === 2 && e.startTime && !e.endTime)
              || (this.state === 3 && e.startTime && e.endTime))
          .filter(e => !this.correctionRequired
              || (e.nameWrong || e.nameEnWrong || e.schoolGradeWrong || e.sexWrong || e.dateOfBirthWrong));
    },
    examTerm() {
      return this.examTerms.find(t => t.examTermId == this.examTermId);
    },
    stateOptions() {
      return (this.examTerm && this.examTerm.marked)
          ? [
            {id: false, name: "すべて"},
            {id: 1, name: "未受検"},
            {id: 3, name: "受検済"}
          ]
          : [
            {id: false, name: "すべて"},
            {id: 1, name: "未受検"},
            {id: 2, name: "受検中"},
            {id: 3, name: "受検済"}
          ];
    },
    examGradeOptions() {
      const options = [];
      options.push({ id: "all", name: "すべて" });
      this.examGrades.forEach(g => options.push({ id: g.code, name: g.name }));
      return options;
    },
  },
  mounted() {
    Service.getGrades().then(grades => {
      this.examGrades = grades;
    });
    Service.getExamTerms().then(examTerms => {
      this.examTerms = examTerms;
      this.$store.commit("setExamTerms", examTerms);
      this.examTermId = this.currentExamTerm?.examTermId;
      this.changeExamTerm();
    })
  },
  beforeDestroy() {
    if (this.timeoutHandle) clearTimeout(this.timeoutHandle);
  },
  methods: {
    changeExamTerm() {
      const siteId = this.siteId;
      this.sites = [];
      this.allExaminees = [];
      Service.getSites(this.examTermId).then(sites => {
        this.sites = sites;
        if (!sites.some(site => site.siteId === siteId)) {
          this.siteId = sites.length === 0 ? null : sites[0].siteId;
        }
        this.loadExaminees();
      })
    },
    loadExaminees() {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = null;
      }
      this.allExaminees = [];
      if (!this.examTermId) return;
      if (this.examTerm.marked && this.state == 2) this.state = false;
      this.loading = true;
      this.reloadExaminees();
    },
    reloadExaminees() {
      if (!this.siteId) return;
      return Service.getExaminees(this.siteId, this.examTermId).then(data => {
        this.allExaminees = data.examinees;
        this.listFile = data.listFile;
        this.cardFile = data.cardFile;
        Service.getExamSessions(this.siteId, this.examTermId).then(data => {
          this.examStatus = data.examStatus;
          for (const examineeId in this.examStatus) {
            if (this.examStatus[examineeId].isAnswering) {
              const examStatus = this.examStatus[examineeId];
              // リスニング回答中のみ解答中アイコンを表示する
              if (examStatus.status[examStatus.progress] === 'L' || examStatus.status[examStatus.progress] === 'LN') {
                examStatus.status[examStatus.progress] = 'C';
              } else if (examStatus.status[examStatus.progress] === 'LS' || examStatus.status[examStatus.progress] === 'LSN')
                // リスニング例題解答中は次のリスニング問題を解答中という表示にする
                examStatus.status[examStatus.progress + 1] = 'C';
            }
          }
          this.timeoutHandle = setTimeout(() => {this.reloadExaminees();}, 20000);
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    editExaminee(examineeId) {
      this.examineeId = examineeId;
      this.$refs.examineeModal.open();
    },
    closeExamineeModal(reload) {
      if (reload) this.loadExaminees();
      this.$refs.examineeModal.close();
    },
    openUrlModal() {
      this.$refs.urlModal.open();
    },
    closeUrlModal() {
      this.$refs.urlModal.close();
    },
    downloadExamineeList() {
      window.open("/download/endpoint/exam/download_examinee_list" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId,"_blank");
    },
    downloadExamineeCard() {
      window.open("/download/endpoint/exam/download_examinee_card" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId, "_blank");
    },
    isDisplayStatus(examineeId) {
      return this.examStatus[examineeId] && !!this.examStatus[examineeId].startTime;
    },
    getRowClass(index) {
      return index % 2 === 0 ? 'odd' : 'even';
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  position: relative;

  &.wrong {
    background-color: #ffddaa;
    &:before {
      content: 'warning';
      font-family: "Material Icons";
      position: absolute;
      top: 0;
      right: 0;
      color: #ffa500;
    }
    &.corrected:before {
      right: 1em;
    }
  }
  &.corrected {
    background-color: #d0ffd0;
    &:after {
      content: 'check';
      font-family: "Material Icons";
      position: absolute;
      top: 0;
      right: 0;
      color: #008800;
    }
  }
}
.output-buttons {
  position: absolute;
  top: -0.5rem;
  right: 0;
}
.url-button-container {
  position: relative;
  .url-button {
    position: absolute;
    padding: 1rem;
    top: -0.5rem;
    border-radius: 2rem;
    border: 4px solid #6984CA;
    font-weight: bold;
    color: #666;
    width: 13rem;
  }
}
.question-type {
  font-size: small;
  color: #6984ca;
  opacity: 0.9;
  &.sample {
    padding-left: 8px;
  }
}
.odd {
  background-color: #f9f9f9;
}
.even {
  background-color: #ffffff;
}
</style>
